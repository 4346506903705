import { fetchPostData } from "~/composables/general";
import { useUserStore } from "~/store/auth";

import WWSS from "~/utils/wwss";
import enviromentData from "~/constants/enviroment";

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const nuxtApp = useNuxtApp();

  if (process.server) return;

  userStore.setLoading(true);

  let indexRedirectPath;
  // MFA DEV
  if (enviromentData.ENV == "DEV") {
    indexRedirectPath = await handleDevMfa(userStore);

    userStore.setLoading(false);

    if (!to.name.includes("login") && !localStorage.getItem("loggedIn")) {
      return navigateTo(nuxtApp.$localePath("/login"), { replace: true });
    } else if (to.name.includes("login") && localStorage.getItem("loggedIn")) {
      return navigateTo(nuxtApp.$localePath(indexRedirectPath), {
        replace: true,
      });
    } else {
      return;
    }
  }

  let loggedIn = localStorage.getItem("loggedIn") === "true";
  if (loggedIn) {
    const expirationDate = parseInt(localStorage.getItem("expirationDate"));

    if (expirationDate < new Date().getTime()) {
      handleExpiredSession(userStore);
      return navigateTo(nuxtApp.$localePath("/login"), { replace: true });
    } else {
      restoreUserStoreFromLocalStorage(userStore);
    }
  }

  const { $msal } = await useNuxtApp();
  const accounts = $msal().getAccounts();
  const accessToken = await $msal().acquireTokenSilent();
  let isAuthenticated = $msal().isAuthenticated() && accessToken;
  indexRedirectPath = "/";

  if (!loggedIn && isAuthenticated) {
    const user = {
      ...accounts[0],
      bearerToken: accessToken,
    };

    userStore.setUser(user);
    const authResult = await handleAuthenticatedUser(userStore);

    if (authResult) {
      indexRedirectPath = getPathRedirect(indexRedirectPath, userStore);
    }
  }

  userStore.setLoading(false);
  // toggleGlobalLoading(true);

  if (!to.name.includes("login") && !localStorage.getItem("loggedIn")) {
    return navigateTo(nuxtApp.$localePath("/login"), { replace: true });
  } else if (to.name.includes("login") && localStorage.getItem("loggedIn")) {
    return navigateTo(nuxtApp.$localePath(indexRedirectPath), {
      replace: true,
    });
  } else {
    return;
  }
});

/**
 * Obtiene el path objetivo del usuario
 * @param {String} path
 * @param {Object} userStore
 */

function getPathRedirect(path, userStore) {
  const rol = userStore.getUserRole;

  if (rol && !hasRole("admin")) {
    return "/scheduling";
  }
  return path;
}

/**
 * Maneja la autenticación del usuario en backend
 * @param {Object} userStore
 */
async function handleAuthenticatedUser(userStore) {
  let auhtenticated = true;
  const user_name = userStore.getUser.username;

  const backendAuthResult = await fetchPostData(WWSS.login.backend, {
    userprincipalname: user_name,
    product_name: "MSC_WEB",
    product_version: "1.0",
  });

  if (!backendAuthResult.error) {
    // backendAuthResult.CodOperarioMaximo = "RMUGICA";
    userStore.setLogin(backendAuthResult);

    const codOperarioMaximo = userStore.login?.CodOperarioMaximo;

    const maximoAuthResult = await fetchPostData(WWSS.login.profile, {
      item: codOperarioMaximo,
    });

    if (!maximoAuthResult.error) {
      userStore.setProfile(maximoAuthResult[0]);
      handleSuccessfulLogin();
      handleSetLanguage(userStore);
    } else {
      auhtenticated = false;
      handleAuthError(userStore, maximoAuthResult.error.code);
    }
  } else {
    auhtenticated = false;
    handleAuthError(userStore, backendAuthResult.error.code);
  }
  return auhtenticated;
}

/**
 * MAneja el mensaje de error de login
 * @param {Object} userStore
 * @param {String} errorCode
 */
const handleAuthError = (userStore, errorCode) => {
  const nuxtApp = useNuxtApp();
  let errorMessage = nuxtApp.$i18n.t(`errors.messages.${errorCode}`);
  userStore.setError(errorMessage);
};

/**
 * Maneja la expiración de a sesión del usuario
 * @param {Object} userStore
 */
const handleExpiredSession = (userStore) => {
  userStore.resetLogin();
  localStorage.removeItem("loggedIn");
  localStorage.clear();
  handleAuthError(userStore, "sessionExpired");
};

/**
 * Maneja la asignación de la información del usuario de la sesión al store
 * @param {Object} userStore
 */
const restoreUserStoreFromLocalStorage = (userStore) => {
  userStore.setUser(JSON.parse(localStorage.getItem("user")));
  userStore.setLogin(JSON.parse(localStorage.getItem("login")));
  userStore.setProfile(JSON.parse(localStorage.getItem("profile")));
};

/**
 * Maneja el inicio de sesión en la sesión
 */
const handleSuccessfulLogin = () => {
  const currentDate = new Date();
  const expirationDate = new Date(currentDate);
  expirationDate.setHours(currentDate.getHours() + 24);

  localStorage.setItem("expirationDate", expirationDate.getTime());
  localStorage.setItem("loggedIn", true);
};

/**
 * Maneja el inicio de sesión en la sesión
 */
const handleSetLanguage = (userStore) => {
  const nuxtApp = useNuxtApp();
  const profileLanguage = userStore.getProfile.LANGUAGE;
  if (profileLanguage !== "ES") {
    nuxtApp.$i18n.setLocale = "en";
  }
};

const handleDevMfa = async (userStore) => {
  const user = {
    homeAccountId: "",
    environment: "",
    tenantId: "",
    username: "inbujan.ext@acciona.com",
    localAccountId: "",
    name: "Bujan Sagastibelza, Iñaki",
    idTokenClaims: {},
    authorityType: "",
    idToken: "",
    bearerToken: "",
  };

  userStore.setUser(user);
  const authResult = await handleAuthenticatedUser(userStore);

  let indexRedirectPath = "/";

  if (authResult) {
    indexRedirectPath = getPathRedirect(indexRedirectPath, userStore);
  }
  return indexRedirectPath;
};
